<template>
  <div class="meter-device-add pa-10">

    <title-page icon="ico-status-charging" class="mb-0">
      {{ $t("meter-device.add") }}
    </title-page>

    <v-breadcrumbs
        class="pl-0"
        :items="[
          {text: $tc('meter-device.meter-device', 2), to: {name: 'meter-device'}, exact: true},
          {text: $t('meter-device.add')},
        ]"
        divider=">"
    ></v-breadcrumbs>




    <div class="mt-5">

      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="save">
            <headline>{{ $tc('meter-device.meter-device') }}</headline>
            <v-row>
              <v-col cols="12">
                <form-field
                    type="select"
                    :label="$t('meter-device.type')"
                    v-model="form.type"
                    :items="types"
                    :rules="[
                      commonRules.required
                    ]"
                    :disabled="isEdit"
                    col-field="9"
                />
              </v-col>
            </v-row>

            <template v-if="form.type">
              <v-row>
                <v-col cols="12" md="6">
                  <form-field
                      type="select"
                      :label="$t('meter-device.statement-mode')"
                      v-model="form.statementMode"
                      :items="statementModes"
                      :rules="[
                        commonRules.required
                      ]"
                      disabled
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-field
                      type="select"
                      :label="$t('meter-device.statement-type')"
                      v-model="form.statementType"
                      :items="statementTypes"
                      :rules="[
                        commonRules.required
                      ]"
                      disabled
                  />
                </v-col>
              </v-row>

              <v-row  v-if="form.statementMode === 'API'">
                <v-col cols="12">
                  <form-field
                      type="select"
                      :label="$t('meter-device.meter')"
                      v-model="selectedApiMeter"
                      item-text="name"
                      :items="meters"
                      col-field="9"
                      return-object
                  />
                </v-col>
              </v-row>

            </template>

            <template v-if="form.type && form.statementType">
              <headline class="mt-10">
                {{ $tc("general-information") }}
              </headline>
              <v-row>
                <v-col cols="12" md="6">
                  <form-field
                      :label="$t('name')"
                      v-model="form.name"
                      :rules="[
                          commonRules.required,
                      ]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-field
                      :label="$t('external-reference')"
                      v-model="form.externalId"
                      :rules="[

                      ]"
                      :disabled="form.statementMode === 'API'"
                  />
                </v-col>
              </v-row>

              <v-row  v-if="!isExternalOperator">
                <v-col cols="12" md="6">
                  <form-field
                      :label="$t('meter-device.brand')"
                      v-model="form.brand"
                      :rules="[

                      ]"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <form-field
                      :label="$t('meter-device.model')"
                      v-model="form.model"
                  />
                </v-col>
              </v-row>



              <headline class="mt-10">
                {{ $tc("customer.customer") }}
              </headline>
              <v-row>
                <v-col cols="12">
                  <form-field
                      type="customer-select"
                      :label="$tc('customer.customer',1)"
                      v-model="form.customer.id"
                      selection-with-no-extra
                      col-field="9"
                  />
                </v-col>
              </v-row>

              <headline class="mt-10">
                {{ $tc("location.location") }}
              </headline>
              <template v-if="form.location">

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('name')"
                        :value="form.location?.name"
                        disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="text-center">
                    <form-field
                        :label="$t('organization.organization')"
                        :value="form.organization?.name"
                        disabled
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('street')"
                        :value="form.location?.address?.street"
                        disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('state')"
                        :value="form.location?.address?.state"
                        disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('city')"
                        :value="form.location?.address?.city"
                        disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('zipcode')"
                        :value="form.location?.address?.zipcode"
                        disabled
                    />
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn
                        @click="$refs.locationModal.open()"
                        small
                    >
                      {{ $t('charging-point.edit-location') }}
                    </v-btn>

                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col class="text-center">
                    <v-btn
                        @click="$refs.locationModal.open()"
                        small
                    >
                      {{ $t('charging-point.edit-location') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <template  v-if="!isExternalOperator">
                <headline class="mt-10">
                  {{ $t("settings") }}
                </headline>
                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        input-type="number"
                        min="0"
                        :label="$t('meter-device.initial-meter-value')"
                        v-model.number="form.initialMeterValue"
                        :rules="[

                        ]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        input-type="number"
                        min="0"
                        :label="$t('meter-device.max-meter')"
                        v-model.number="form.maxMeterValue"
                        :rules="[

                        ]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">

                    <form-field
                        :label="$t('meter-device.last-meter-value')"
                        v-model.number="form.lastMeterValue"
                        :rules="[]"
                        input-type="number"
                        min="0"
                    />

                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('meter-device.last-meter-date')"
                        v-model.number="form.lastMeterDate"
                        :rules="[]"
                        type="date"
                        min="0"
                        aria-readonly="true"
                    />

                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        input-type="number"
                        :label="$t('electricity-tariff.value-per-kwh')"
                        v-model.number="form.energyProviderKwhPrice"
                        :rules="[]"
                    />
                  </v-col>
                </v-row>
              </template>



              <template v-if="isExternalOperator">
                <headline class="mt-10">
                  {{ $t("enums.meter-device.external-operator") }}
                </headline>
                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        type="select"
                        v-model="form.externalOperatorBillingCycle"
                        :items="billingCycles"
                        :label="$tc('meter-device.external-operator-billing-cycle')"
                        :rules="[
                      commonRules.required,
                  ]"
                    >
                    </form-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        type="select"
                        v-model="form.externalOperatorSupplier"
                        :items="externalOperatorSuppliers"
                        :label="$tc('meter-device.external-operator-supplier')"
                        item-value="id"
                        item-text="name"
                        :rules="[
                            commonRules.required,
                        ]"
                    >
                    </form-field>
                  </v-col>
                </v-row>
              </template>



              <v-row>

              </v-row>






              <v-row class="mt-10">
                <v-col cols="12" class="text-center">
                  <v-btn type="submit" color="primary">
                    {{ $t('save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-col>
      </v-row>
    </div>

    <location-modal ref="locationModal" :location.sync="location" />

  </div>
</template>

<script>

import RulesMixin from "@mixins/RulesMixin";
import MeterDeviceRepository from "@repository/MeterDeviceRepository";
import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal";
import LocationRepository from "@repository/LocationRepository";

export default {
  components: {
    LocationModal
  },
  data() {
    return {
      selectedApiMeter: null,
      form: {
        type: null,
        externalId: null,
        location: null,
        organization: null,
        customer: {},
        statementMode: null,
        statementType: null,
        meterIdFromApi: null,
        meterNameFromApi: null,
        externalOperatorSupplier: 'STANDARD',
        externalOperatorBillingCycle: 'MONTHLY',
      },
      types:[],
      meters: [],
      externalOperatorSuppliers: [],
      OrganizationName: null,
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if (!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadExternalOperatorSuppliers()
    this.loadTypes().then(() => {
      if (this.isEdit) {
        this.loadData()
      } else {
        if(this.$route.query.type) {
          this.form.type = this.$route.query.type
          this.form.externalId = this.$route.query.externalId
        }
      }
    })




  },
  created() {
    // if (this.$route.params.idIndex !== undefined) {
    //   this.assignFormData(this.$route.params);
    // }
  },
  watch: {

    'form.statementMode'(newMode) {
      if (newMode === 'API') {
        this.loadApiMeterDevices()
      }
    },

    'form.type'() {
      this.form.statementMode = this.statementModes[0]?.value
      this.form.statementType = this.statementTypes[0]?.value
    },

    selectedApiMeter(val) {
      this.form.externalId = val._id
      this.form.name = val.name
    },
  },

  computed: {
    id() {
      return this.$route.params.id ?? null
    },

    isEdit() {
      return !!this.id
    },


    billingCycles() {
      const items = []
      let types = this.$t('enums.billing-cycle');
      for(let type in types) {
        items.push({text: types[type], value: type})
      }
      return items
    },

    isExternalOperator() {
      return this.form.type === 'external-operator'
    },

    statementModes() {

      if (this.form?.type === 'mid-meter' || this.form?.type === 'external-operator') {
        return [
          {text: this.$t('enums.statement-mode.MANUAL'), value: 'MANUAL'},
        ]
      }
      if (this.form?.type === 'smart-cable') {
        return [
          {text: this.$t('enums.statement-mode.IMPORT'), value: 'IMPORT'},
        ]
      }
      if (this.form?.type === 'connected-plug') {
        return [
          {text: this.$t('enums.statement-mode.API'), value: 'API'},
        ]
      }
      return null
    },

    statementTypes() {
      if (this.form?.type === 'mid-meter') {
        return [
          {text: this.$t('enums.statement-type.INDEX'), value: 'INDEX'},
        ]
      }
      if (this.form?.type === 'smart-cable') {
        return [
          {text: this.$t('enums.statement-type.SESSION'), value: 'SESSION'},
        ]
      }
      if (this.form?.type === 'connected-plug') {
        return [
          {text: this.$t('enums.statement-type.INDEX'), value: 'INDEX'},
        ]
      }
      if (this.form?.type === 'external-operator') {
        return [
          {text: this.$t('enums.statement-type.INVOICE'), value: 'INVOICE'},
        ]
      }
      return []
    },

    location: {
      get() {
         if(this.$route.query?.locationId){
           this.loadLocation(this.$route.query.locationId)
           let query = { ...this.$route.query };
           delete query.locationId;
           this.$router.replace({ query });
         }
        if(this.form?.location) {
          return {...this.form?.location, organization: this.form?.organization, organizationId: undefined}
        }
        return null
      },

      set(value) {
        const location = this.cloneObj(value)
        const { organization } = location
        delete location.organization
        this.form.location = location
        this.form.organization = organization
      }
    }
  },

  methods: {
    assignFormData(data) {
      this.form.type = data.type;
      // Déclencher manuellement le watch après l'assignation de form.type
      this.$nextTick(() => {
        this.$options.watch['form.type'].call(this);
      });
    },
    cancel() {

    },

    loadTypes() {
      return new Promise((resolve, reject) => {
        MeterDeviceRepository.metertypes().then(data => {
          this.types = data.map(d => ({text: this.$t('enums.meter-device.' + d), value: d}))
          resolve(this.types)
        }).catch(err => {
          this.notifyError(err)
          reject(err)
        })
      })
    },

    loadExternalOperatorSuppliers() {
      return new Promise((resolve, reject) => {
        MeterDeviceRepository.externalOperatorSuppliers().then(data => {
          this.externalOperatorSuppliers = data
          resolve(this.externalOperatorSuppliers)
        }).catch(err => {
          this.notifyError(err)
          reject(err)
        })
      })
    },

    loadApiMeterDevices() {
      this.meters = []
      MeterDeviceRepository.getdomoconsometers().then(data => {
        this.meters = data.data
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        MeterDeviceRepository.get(this.id).then(data => {
          if(!data.customer) {
            data.customer =  {}
          }
          this.form = data
          this.showLoading(false)
          resolve(data)
        }).catch(err => {
          this.notifyError(err)
          this.showLoading(false)
          reject(err)
        })
      })
    },
    loadLocation(locationId){//fill location field when add new MD from Location
      LocationRepository.getByIdentifier(locationId).then(data => {
        this.form.location  = data
        this.form.organization=data.organization
       // this.location = data
      }).catch(err => {
        this.notifyError(err)
      })
    },
    save() {
      if (!this.location) {
        this.notifyError('location.select-location')
        return
      }
      if (this.$refs.form.validate()) {
       this.showLoading(true)

        const form = this.cloneObj(this.form)

        const locationId = form.location?.id
        const customerId = form.customer?.id
        const organizationId = form.organization?.id

        delete form.location
        delete form.customer
        delete form.organization

        const data = {
          ...form,
          locationId,
          customerId,
          organizationId,
        }
        if (this.isEdit) {
            MeterDeviceRepository.update(this.id, data).then(response => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('update-success'))
            this.$router.push({name: 'meter-device'})
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        } else {
          MeterDeviceRepository.create(data).then(response => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('created-successfully'))
            this.$router.push({name: 'meter-device'})
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      } else {
        this.notifyError('form-has-errors')
      }
    },

    deleteItem() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MeterDeviceRepository.delete(this.id).then(result => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('delete-success'))
            this.$router.replace({name: 'meter-device'})
          }).catch(err => {
            this.notifyError(err)
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.meter-device-add {

}

</style>
